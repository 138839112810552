.bonus-list {
  display: grid;
  grid-template: repeat(auto-fill, 1fr) / 1fr;
  gap: 2px 0;
  margin: 20px 0;
  max-width: 360px;
  list-style: none;

  &--body {
    .label {
      padding: 5px 6px;
      background-color: var(--c-white-smoke);
      color: #4A108B;
      font-weight: 900;
      border-radius: 7px;
      line-height: 1;

      &--up {
        padding-left: 22px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzRBMTA4QiIgZD0iTTcuNCAxMmgxLjU5djVjMCAuNTUuNDUgMSAxIDFoNGMuNTUgMCAxLS40NSAxLTF2LTVoMS41OWMuODkgMCAxLjM0LTEuMDguNzEtMS43MUwxMi43IDUuN2EuOTk2Ljk5NiAwIDAgMC0xLjQxIDBMNi43IDEwLjI5Yy0uNjMuNjMtLjE5IDEuNzEuNyAxLjcxWiIvPjwvc3ZnPg==);
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 20px;
    min-height: 2.2em;
    padding: 0 21px;
    color: var(--c-white-smoke);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.3;

    span.label {
      white-space: nowrap;
      text-align: right;
    }

    &.lock {
      padding-left: 52px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1vcGFjaXR5PSIuNSIgZD0iTTE4IDcuNjY3aC0xVjUuNzYyQzE3IDMuMTMzIDE0Ljc2IDEgMTIgMVM3IDMuMTMzIDcgNS43NjJ2MS45MDVINmMtMS4xIDAtMiAuODU3LTIgMS45MDR2OS41MjRDNCAyMC4xNDMgNC45IDIxIDYgMjFoMTJjMS4xIDAgMi0uODU3IDItMS45MDVWOS41NzFjMC0xLjA0Ny0uOS0xLjkwNC0yLTEuOTA0Wm0tNiA4LjU3MWMtMS4xIDAtMi0uODU3LTItMS45MDUgMC0xLjA0Ny45LTEuOTA0IDItMS45MDRzMiAuODU3IDIgMS45MDRjMCAxLjA0OC0uOSAxLjkwNS0yIDEuOTA1Wk05IDcuNjY3VjUuNzYyYzAtMS41ODEgMS4zNC0yLjg1NyAzLTIuODU3czMgMS4yNzYgMyAyLjg1N3YxLjkwNUg5WiIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: left 18px center;
    }

    &.check {
      padding-left: 52px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTIuNSIgY3k9IjExLjUiIHI9IjYuNSIgZmlsbD0iIzRBMTA4QiIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0xMC45NDkgMTIuNy0xLjQ1LTEuNDI1QS45MzMuOTMzIDAgMCAwIDguODEgMTFjLS4yNzUgMC0uNTEyLjEtLjcxMi4zYS45NDguOTQ4IDAgMCAwLS4yNzUuN2MwIC4yODQuMDkxLjUxNy4yNzUuN2wyLjE1IDIuMTVjLjIuMi40MzMuMy43LjMuMjY2IDAgLjUtLjEuNy0uM2w0LjI1LTQuMjVjLjItLjIuMjk2LS40MzMuMjg3LS43YTEuMDU0IDEuMDU0IDAgMCAwLS4yODctLjcgMS4wMiAxLjAyIDAgMCAwLS43MTMtLjMxMi45My45MyAwIDAgMC0uNzEyLjI4N0wxMC45NDkgMTIuN1ptLTIuOCA5LjA1LTEuNDUtMi40NS0yLjc1LS42YS45NDMuOTQzIDAgMCAxLS42LS4zODcuOTI4LjkyOCAwIDAgMS0uMTc1LS42ODhsLjI3NS0yLjgyNS0xLjg3NS0yLjE1YS45MzQuOTM0IDAgMCAxLS4yNS0uNjVjMC0uMjUuMDgzLS40NjYuMjUtLjY1TDMuNDQ5IDkuMmwtLjI3NS0yLjgyNWEuOTI4LjkyOCAwIDAgMSAuMTc1LS42ODcuOTQzLjk0MyAwIDAgMSAuNi0uMzg4bDIuNzUtLjYgMS40NS0yLjQ1YS45ODMuOTgzIDAgMCAxIC41NS0uNDM3Ljk3Ljk3IDAgMCAxIC43LjAzN2wyLjYgMS4xIDIuNi0xLjFhLjk3Ljk3IDAgMCAxIC43LS4wMzdjLjIzMy4wNzUuNDE2LjIyLjU1LjQzN2wxLjQ1IDIuNDUgMi43NS42Yy4yNS4wNS40NS4xOC42LjM4OC4xNS4yMDguMjA4LjQzNy4xNzUuNjg3TDIwLjU0OSA5LjJsMS44NzUgMi4xNWMuMTY2LjE4NC4yNS40LjI1LjY1cy0uMDg0LjQ2Ny0uMjUuNjVsLTEuODc1IDIuMTUuMjc1IDIuODI1YS45MjguOTI4IDAgMCAxLS4xNzUuNjg4Ljk0My45NDMgMCAwIDEtLjYuMzg3bC0yLjc1LjYtMS40NSAyLjQ1YS45ODMuOTgzIDAgMCAxLS41NS40MzguOTcuOTcgMCAwIDEtLjctLjAzOGwtMi42LTEuMS0yLjYgMS4xYS45Ny45NyAwIDAgMS0uNy4wMzguOTg0Ljk4NCAwIDAgMS0uNTUtLjQzOFoiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: left 18px center;
    }
  }
}
