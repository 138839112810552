.accordion {
  display: grid;
  grid-template: 1fr / auto;
  gap: 10px 0;
  margin: 0 auto;
  padding: 0 calc(var(--app-gap) / 2);
  color: var(--c-white-smoke);
  width: var(--app-wadth);

  @media (width >= 992px) {
    padding: 0;
  }

  .unclamped {
    .svg-icon {
      transform: rotate(0.5turn);
    }
  }

  &__item {
    --clamp-height: auto;

    position: relative;
    display: grid;
    grid-template: auto auto / 1fr;
    padding: 0 50px 0 20px;
    background-color: var(--c-dark-purple);
    border-radius: 20px;

    @include min(tablet-landscape) {
      padding: 0 60px 0 30px;
    }

    &:not(.unclamped) {
      --clamp-height: 0;

      background-color: var(--c-ultramarine);
    }

    .expand__icon {
      position: absolute;
      inset: 11px 10px 0 auto;
      width: 48px;
      height: 48px;
      fill: var(--c-white-smoke);
      pointer-events: none;

      @media (width >= 992px) {
        inset: 15px 20px 0 auto;
      }
    }

    &-title {

    }

    &-desc {
      margin: 0;
      height: var(--clamp-height);
      overflow: hidden;
      transition: height 1.25s ease;
    }
  }

  .title-medium {
    margin: 0;
    padding: 20px 0 20px;
    max-inline-size: auto;
    text-wrap: none;
    min-width: 100%;
    cursor: pointer;
    line-height: 1.3;
  }

  .body-medium {
    padding: 0 0 20px;
    line-height: 1.3;
  }
}
