@media (width <= 1200px) {
  .swiper {
    &-wrapper {
      overflow: hidden;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
      max-width: 100%;
      display: flex;
      margin: 0 auto -40px;
      padding-bottom: 40px;

      //scrollbar-width: none;
      //scrollbar-color: transparent transparent;
    }

    &-slide {
      scroll-snap-align: center;
      display: flex;
      border-style: solid;
      border-color: transparent;
      border-width: 0;

      &:not(:first-child):not(:last-child) {
        border-left-width: calc(var(--app-gap) / 2);
        border-right-width: calc(var(--app-gap) / 2);
      }

      &:first-child {
        border-left-width: var(--app-gap);
        border-right-width: calc(var(--app-gap) / 2);
      }

      &:last-child {
        border-left-width: calc(var(--app-gap) / 2);
        border-right-width: var(--app-gap);
      }
    }
  }
}

@media (width >= 1200px) {
  .swiper {
    position: relative;
    overflow: hidden;
    list-style: none;
    max-width: 100vw;
    z-index: 1;
    display: block;
    padding: 80px 0 0;

    &-wrapper {
      position: relative;
      width: 100%; height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
      box-sizing: content-box;
    }

    &-button-prev,
    &-button-next {
      position: absolute;
      inset: 0 0 auto 0;
      margin: auto;
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-color: var(--c-indigo);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTExLjI5IDguMjkyLTQuNTkgNC41OWEuOTk3Ljk5NyAwIDAgMCAxLjQxIDEuNDFsMy44OS0zLjg4IDMuODggMy44OGEuOTk3Ljk5NyAwIDEgMCAxLjQxLTEuNDFsLTQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMC0xLjQxIDBaIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: 36px;
      cursor: pointer;
    }

    &-button-prev {
      transform: translateX(-55px) rotate(-0.25turn);
      background-position: center center;
    }

    &-button-next {
      transform: translateX(55px) rotate(0.25turn);
      background-position: center center;
    }

    &-button-disabled {
      opacity: 0.5;
      cursor: default;
      filter: contrast(90%) grayscale(25%);
    }

    .card-bonus-container {
      scroll-snap-align: center;
      border-color: transparent;
      border-left-style: solid;
      border-right-style: solid;
      border-left-width: 20px;
      border-right-width: 20px;
    }
  }

  .swiper-android .swiper-slide,
  .swiper-ios .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }

  .swiper-horizontal {
    touch-action: pan-y;
  }

  .swiper-slide-invisible-blank {
    visibility: hidden;
  }

  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}
