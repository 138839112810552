.demo {
  position: fixed;
  inset: 0 auto auto 0;
  z-index: 9999;
  font-size: 16px;
  padding: 5px;
  background: Crimson;
}

.demo-auth {
  list-style: none;
  margin: 0; padding: 0;
  display: flex;
  gap: 0 10px;
}

.hero-bonus__progress {
  img {
    margin-top: var(--v-offset-sm);
    max-width: 100%;
    height: auto;
  }
}

html.demo-hero-unauthorised {
  .hero-bonus__gifts {
    display: block;
  }

  .hero-bonus__progress {
    display: none;
  }
}

html.demo-hero-authorised {
  .hero-bonus__gifts {
    display: none;
  }

  .hero-bonus__progress {
    display: block;
  }
}
