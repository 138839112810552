.hero-gifts {
  display: grid;
  grid-template: 1fr 1fr 1fr / auto;
  gap: 15px 0;
  margin: var(--v-offset-sm) 0;

  @include min(phone-portrait) {
    grid-template: auto / 1fr 1fr 1fr;
    gap: 0 15px;
    margin: 0;
  }

  @include min(tablet-landscape) {
    gap: 0 25px;
  }

  &__item {
    display: grid;
    grid-template: auto / fit-content(100px) auto;
    gap: 0 10px;
    align-items: center;

    @include min(phone-portrait) {
      grid-template: auto 1fr / auto;
      gap: 20px 0;
      align-items: unset;
    }

    .badge {
      width: 70px;
      height: 70px;

      @include min(phone-portrait) {
        max-height: 90px;
        width: 100%;
        height: 100%;
      }
    }

    &-desc {
      overflow: hidden;
      display: grid;
      place-content: center;
      width: 100%;
      height: 100%;
      padding: 14px;
      background-color: var(--c-indigo);
      box-shadow: 0 0 0 1px inset var(--c-dark-orchid);
      border-radius: 15px;
      text-align: center;

      @include min(phone-portrait) {
        display: block;
        place-content: unset;
        height: auto;
      }

      @include min(tablet-landscape) {
        padding: 18px 22px 20px;
      }

      .title-medium {
        margin: 0 0 calc(var(--v-offset-sm) / 3);
        color: var(--c-white-smoke);
        font-weight: 800;
        font-size: 1.9em;
      }

      .body-medium {
        margin: 0;
        color: var(--c-white-smoke);
        font-weight: 460;
        font-size: 1.5em;
      }
    }
  }
}
