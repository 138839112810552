.title-medium {
  margin: 0;
  font-size: 2.8em;
  font-weight: 700;
}

.body-medium {
  margin: 0;
  font-size: 2em;
  font-weight: 400;
}

.headline-medium {
  margin: var(--v-offset-md) auto var(--v-offset-sm);
  font-size: 3.6em;
  font-weight: 860;
  color: var(--c-white-smoke);
  text-transform: uppercase;
  text-align: center;
}
