.pyramid {
  position: relative;

  &__way {
    position: absolute;
    inset: 0 0 20% 11%;
    width: 59%;
    height: 80%;

    path.pyramid-gradient {
      stroke-width: 19;
      stroke: url(#pyramid-way-progress);
    }

    path.pyramid-progress {
      stroke-width: 20;
      stroke: url(#pyramid-way-filling);
      stroke-dasharray: var(--dasharray);
      transition: stroke-dashoffset 475ms linear;
      stroke-dashoffset: calc((var(--dashoffset) / 13 * (var(--user-game-level) - 1)));
    }

    path.pyramid-decor {
      // Если --user-game-level не определен, opacity не сработает, если указан,
      // то приобретет отрицательное значение и .pyramid-decor исчезнет
      opacity: calc(var(--user-game-level) * -1);
      stroke-width: 20;
      stroke: url(#pyramid-way-decor);
      //stroke-dasharray: calc(var(--dasharray) / 6);
      stroke-dasharray: var(--dasharray);
      stroke-dashoffset: var(--dashoffset);
      animation: 4s ease-in-out infinite alternate dash;

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  &__points {
    position: absolute;
    inset: 0 0 19% 8.5%;
    width: 60%;

    &-item {
      --pyramid-point-size: clamp(52px, calc(5vmin + 3vh), 64px);

      cursor: pointer;
      position: absolute;
      transform: translate(-50%, -50%);
      width: var(--pyramid-point-size);
      height: var(--pyramid-point-size);

      &:first-child {
        .badge {
          &:after {
            content: '';
            position: absolute;
            inset: -36px 0 0;
            margin: auto;
            z-index: 10;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNCIgaGVpZ2h0PSIzNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0ZGQzIxRCIgZD0iTTIgOC43NzcgNy4yMDggMjhoMTkuNTg0TDMyIDguNzc3bC04Ljk1OCA2LjgzNUwxNi43OTIgNmwtNS44MzQgOS42MTJMMiA4Ljc3N1oiLz48cGF0aCBmaWxsPSIjNDYwNzg4IiBkPSJtMTYuODU3IDE1LjE4NSAzLjc4OSA0LjcyOS0zLjc4OSA0LjcyOS0zLjc4OC00LjczIDMuNzg4LTQuNzI4WiIvPjwvc3ZnPg==);
            background-position: top center;
            background-repeat: no-repeat;
          }
        }
      }

      &[data-win] {
        box-shadow: 0 0 0 2px var(--c-deep-purple), 0 0 0 5px #FFBE05;
        background-color: var(--c-deep-purple);
        border-radius: 200px;

        &::before,
        &::after {
          position: absolute;
          inset: 0;
          margin: auto;
          z-index: 1;
          width: 100%;
          height: 100%;
          content: '';
          border-radius: 100%;
        }

        &::after {
          box-shadow:
                  -0.4em 0.7em 0.7em 0.2em rgba(239, 3, 146, 0.65),
                  -0.5em -0.5em 0.7em 0.2em rgba(239, 3, 146, 0.65);
          animation: spin 2.6s linear infinite;

          @keyframes spin {
            to {
              transform: rotate(1turn);
            }
          }
        }

        &::before {
          box-shadow:
                  0.5em -0.5em 0.5em 0 rgba(239, 3, 146, 0.65),
                  -0.5em -0.5em 0.5em 0 rgba(239, 3, 146, 0.65);
          animation: spin 2.2s linear infinite reverse;

          @keyframes spin {
            to {
              transform: rotate(1turn);
            }
          }
        }
      }

      &[data-current] {
        box-shadow: 0 0 0 2px rgba(0, 254, 254, 1), 0 0 0 5.5px rgba(0, 254, 254, 1);
        background-color: rgba(0, 254, 254, 1);
        border-radius: 200px;

        &::before,
        &::after {
          position: absolute;
          inset: 0;
          margin: auto;
          z-index: 1;
          width: 100%;
          height: 100%;
          content: '';
          border-radius: 100%;
        }

        &::after {
          box-shadow:
                  -0.5em 0.8em 0.8em 0.3em rgba(0, 254, 254, 0.65),
                  -0.6em -0.6em 0.8em 0.3em rgba(0, 254, 254, 0.65);
          animation: spin 2.6s linear infinite;

          @keyframes spin {
            to {
              transform: rotate(1turn);
            }
          }
        }

        &::before {
          box-shadow:
                  0.6em -0.7em 0.4em 0 rgba(0, 254, 254, 0.65),
                  -0.6em -0.6em 0.4em 0 rgba(0, 254, 254, 0.65);
          animation: spin 2.2s linear infinite reverse;

          @keyframes spin {
            to {
              transform: rotate(1turn);
            }
          }
        }
      }
    }
  }
}

.pyramid-title {
  margin: 0;
}
