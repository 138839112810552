.table-benefits {
  display: grid;
  grid-template: auto 1fr auto auto / repeat(4, minmax(260px, 1fr));
  gap: 0 var(--app-gap);
  padding: 0 var(--app-gap);
  contain: layout;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto -40px;
  padding-bottom: 40px;
  text-align: center;
  color: var(--c-white-smoke);

  @media (width >= 1200px) {
    gap: 0 calc(var(--app-gap) * 2);
    width: calc(var(--app-wadth) + (var(--app-gap) * 2));
    margin: auto;
    padding-bottom: unset;
    overflow: unset;
  }

  &__icon {
    padding: calc(var(--v-offset-sm) / 2) 0 0;
    border-radius: 20px 20px 0 0;
    background-color: #B431FF;

    .badge {
      max-width: 132px;
      max-height: 132px;
      margin: auto;
    }
  }

  &__title {
    padding: calc(var(--v-offset-sm) / 2) calc(var(--v-offset-sm) / 2);
    background-color: #B431FF;

    .title-medium {
      display: grid;
      place-content: center;
      width: 100%;
      height: 100%;
      margin: 0;
      line-height: 1.1;
    }
  }

  &__desc {
    overflow: hidden;
    align-self: start;
    background-color: #B431FF;
    border-radius: 0 0 20px 20px;

    &.unclamped {
      --turn: -1;
      --clamp: none;
    }

    .body-medium {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: var(--clamp, 3);
      -webkit-box-orient: vertical;
      margin: 0;
      padding: 0 calc(var(--v-offset-sm) / 2);
      line-height: 1.3;
    }

    .clamp-button {
      margin-top: calc(var(--v-offset-sm) / 2);
      border-top: 4px solid var(--c-bg-page);
      background: linear-gradient(#a027ff, #7f13ff);
      cursor: pointer;

      .svg-icon {
        display: block;
        margin: auto;
        width: 48px;
        height: 48px;
        fill: var(--c-white-smoke);
        transform: scaleY(var(--turn, 1));
        transition: transform 325ms ease-in-out;
      }
    }
  }
}
