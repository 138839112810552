.gridline {
  --gridlinePadding: var(--padding, 20px);
  --gridlineGap: var(--gap, 20px);
  --gridlineCenter: min(calc(100vw - (var(--gridlinePadding) * 2)), var(--width, 100%));
  --gridlineColumn: calc((var(--gridlineCenter) - 11 * var(--gridlineGap)) / 12);
  --gridlineMargin: calc((100vw - var(--gridlineCenter)) / 2);

  box-sizing: border-box;

  & > * {
    box-sizing: inherit;
  }

  &:not(&--scrolling):not(&--collapse) {
    display: flex;
  }

  &:not(&--collapse):not(&--scrolling) > * {
    margin-left: calc(var(--gridlineColumn) * var(--offset, 0) + (var(--offset, 0) - 1) * var(--gridlineGap) + var(--gridlineGap));
    flex: 1 0 calc((var(--gridlineColumn) * var(--column, 12) + (var(--column, 12) - 1) * var(--gridlineGap)) + var(--gridlineInfinite, 0px));

    &:not(:last-child) {
      margin-right: var(--gridlineGap);
    }
  }

  &--center:not(&--scrolling) > *,
  &--left:not(&--scrolling) > *,
  &--right:not(&--scrolling) > * {
    &[class*=edge] {
      --gridlineInfinite: var(--gridlineMargin);

      &:first-child {
        margin-left: calc(var(--gridlineMargin) * -1);
      }
    }
  }

  & > * {
    &[class*=edge]:not(:first-child) {
      margin-right: calc(var(--gridlineMargin) * -1);
    }
  }

  &--center {
    max-width: var(--gridlineCenter);
    margin-left: auto;
    margin-right: auto;
  }

  &--left,
  &--right {
    max-width: calc(var(--gridlineCenter) + var(--gridlineMargin));

    & > * {
      --gridlineColumn: calc((100% - 11 * var(--gridlineGap)) / 12);
    }
  }

  &--right {
    transform: translateX(var(--gridlineMargin));
  }

  &--scrolling {
    overflow: hidden;
    overflow-x: auto;
    max-width: min(calc(var(--gridlineCenter) + calc(var(--gridlinePadding) * 2)), 100vw);
    margin: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;

    @media (pointer: coarse) {
      padding-bottom: 10px;
    }

    [class*=scrolling] {
      min-width: 100%;
      float: left;
      padding-left: var(--gridlinePadding);
      padding-right: var(--gridlinePadding);
    }
  }
}
