.badge {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;

  &__bg {

  }

  &__icon {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 55%;
    height: 55%;
  }

  &[data-num] {
    &::before {
      content: attr(data-num);
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;
      line-height: 1;
      //font-size: clamp(14px, 5vmin, 26px);
      font-size: 2.9em;
      font-weight: 700;
      color: #FFC21D;
    }

    &[data-win],
    &.badge--checkpoint {
      &::before {
        font-weight: 820;
        color: var(--c-indigo);
      }
    }
  }
}
