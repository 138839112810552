:root {
  --min-fs: .75;
  --max-fs: 1;
  --min-vw: 80;
  --max-vw: 120;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc(var(--min-vw) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw)  - var(--min-vw)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-default, "Noto Sans Display", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-size: var(--font-size-clamp);
  line-height: var(--default-line-height);
  font-weight: 400;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

.gridline {
  --gridlinePadding: var(--padding, 20px);
  --gridlineGap: var(--gap, 20px);
  --gridlineCenter: min(calc(100vw - (var(--gridlinePadding) * 2)), var(--width, 100%));
  --gridlineColumn: calc((var(--gridlineCenter)  - 11 * var(--gridlineGap)) / 12);
  --gridlineMargin: calc((100vw - var(--gridlineCenter)) / 2);
  box-sizing: border-box;
}

.gridline > * {
  box-sizing: inherit;
}

.gridline:not(.gridline--scrolling):not(.gridline--collapse) {
  display: flex;
}

.gridline:not(.gridline--collapse):not(.gridline--scrolling) > * {
  margin-left: calc(var(--gridlineColumn) * var(--offset, 0)  + (var(--offset, 0)  - 1) * var(--gridlineGap)  + var(--gridlineGap));
  flex: 1 0 calc(var(--gridlineColumn) * var(--column, 12)  + (var(--column, 12)  - 1) * var(--gridlineGap)  + var(--gridlineInfinite, 0px));
}

.gridline:not(.gridline--collapse):not(.gridline--scrolling) > :not(:last-child) {
  margin-right: var(--gridlineGap);
}

.gridline--center:not(.gridline--scrolling) > [class*="edge"], .gridline--left:not(.gridline--scrolling) > [class*="edge"], .gridline--right:not(.gridline--scrolling) > [class*="edge"] {
  --gridlineInfinite: var(--gridlineMargin);
}

.gridline--center:not(.gridline--scrolling) > [class*="edge"]:first-child, .gridline--left:not(.gridline--scrolling) > [class*="edge"]:first-child, .gridline--right:not(.gridline--scrolling) > [class*="edge"]:first-child {
  margin-left: calc(var(--gridlineMargin) * -1);
}

.gridline > [class*="edge"]:not(:first-child) {
  margin-right: calc(var(--gridlineMargin) * -1);
}

.gridline--center {
  max-width: var(--gridlineCenter);
  margin-left: auto;
  margin-right: auto;
}

.gridline--left, .gridline--right {
  max-width: calc(var(--gridlineCenter)  + var(--gridlineMargin));
}

.gridline--left > *, .gridline--right > * {
  --gridlineColumn: calc((100% - 11 * var(--gridlineGap)) / 12);
}

.gridline--right {
  transform: translateX(var(--gridlineMargin));
}

.gridline--scrolling {
  max-width: min(var(--gridlineCenter)  + var(--gridlinePadding) * 2, 100vw);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  margin: auto;
  overflow: auto hidden;
}

@media (pointer: coarse) {
  .gridline--scrolling {
    padding-bottom: 10px;
  }
}

.gridline--scrolling [class*="scrolling"] {
  float: left;
  min-width: 100%;
  padding-left: var(--gridlinePadding);
  padding-right: var(--gridlinePadding);
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-ext-italic.67416da3.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-italic.3bab7b29.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-vietnamese-italic.c85c0321.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-ext-italic.5899b6bd.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-italic.17be0412.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-ext-normal.225a572b.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-normal.cb2f6287.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-vietnamese-normal.fd255957.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-ext-normal.f2e35548.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-normal.bcad0343.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --z-index-modal: 1000;
  --c-bg-page: #32007c;
  --c-persian-indigo: #3b048c;
  --c-white-smoke: #f5f5f5;
  --c-dark-orchid: #aa14ea;
  --c-mauvein: #ef0392;
  --c-indigo: #51199c;
  --c-deep-purple: #532693;
  --c-dark-brown: #664725;
  --c-ultramarine: #4709a4;
  --c-dark-purple: #6505b7;
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --min-fs: .8;
  --max-fs: 1;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}

@media (min-width: 992px) {
  :root {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.title-medium {
  margin: 0;
  font-size: 2.8em;
  font-weight: 700;
}

.body-medium {
  margin: 0;
  font-size: 2em;
  font-weight: 400;
}

.headline-medium {
  margin: var(--v-offset-md) auto var(--v-offset-sm);
  color: var(--c-white-smoke);
  text-transform: uppercase;
  text-align: center;
  font-size: 3.6em;
  font-weight: 860;
}

html, body {
  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
}

.page {
  background-color: var(--c-bg-page);
  padding: 0 0 100px;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.pyramid {
  position: relative;
}

.pyramid__way {
  width: 59%;
  height: 80%;
  position: absolute;
  inset: 0 0 20% 11%;
}

.pyramid__way path.pyramid-gradient {
  stroke-width: 19px;
  stroke: url("#pyramid-way-progress");
}

.pyramid__way path.pyramid-progress {
  stroke-width: 20px;
  stroke: url("#pyramid-way-filling");
  stroke-dasharray: var(--dasharray);
  stroke-dashoffset: calc(var(--dashoffset) / 13 * (var(--user-game-level)  - 1));
  transition: stroke-dashoffset .475s linear;
}

.pyramid__way path.pyramid-decor {
  opacity: calc(var(--user-game-level) * -1);
  stroke-width: 20px;
  stroke: url("#pyramid-way-decor");
  stroke-dasharray: var(--dasharray);
  stroke-dashoffset: var(--dashoffset);
  animation: 4s ease-in-out infinite alternate dash;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.pyramid__points {
  width: 60%;
  position: absolute;
  inset: 0 0 19% 8.5%;
}

.pyramid__points-item {
  --pyramid-point-size: clamp(52px, calc(5vmin + 3vh), 64px);
  cursor: pointer;
  width: var(--pyramid-point-size);
  height: var(--pyramid-point-size);
  position: absolute;
  transform: translate(-50%, -50%);
}

.pyramid__points-item:first-child .badge:after {
  content: "";
  z-index: 10;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNCIgaGVpZ2h0PSIzNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0ZGQzIxRCIgZD0iTTIgOC43NzcgNy4yMDggMjhoMTkuNTg0TDMyIDguNzc3bC04Ljk1OCA2LjgzNUwxNi43OTIgNmwtNS44MzQgOS42MTJMMiA4Ljc3N1oiLz48cGF0aCBmaWxsPSIjNDYwNzg4IiBkPSJtMTYuODU3IDE1LjE4NSAzLjc4OSA0LjcyOS0zLjc4OSA0LjcyOS0zLjc4OC00LjczIDMuNzg4LTQuNzI4WiIvPjwvc3ZnPg==");
  background-position: top;
  background-repeat: no-repeat;
  margin: auto;
  position: absolute;
  inset: -36px 0 0;
}

.pyramid__points-item[data-win] {
  box-shadow: 0 0 0 2px var(--c-deep-purple), 0 0 0 5px #ffbe05;
  background-color: var(--c-deep-purple);
  border-radius: 200px;
}

.pyramid__points-item[data-win]:before, .pyramid__points-item[data-win]:after {
  z-index: 1;
  content: "";
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.pyramid__points-item[data-win]:after {
  animation: 2.6s linear infinite spin;
  box-shadow: -.4em .7em .7em .2em #ef0392a6, -.5em -.5em .7em .2em #ef0392a6;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.pyramid__points-item[data-win]:before {
  animation: 2.2s linear infinite reverse spin;
  box-shadow: .5em -.5em .5em #ef0392a6, -.5em -.5em .5em #ef0392a6;
}

.pyramid__points-item[data-current] {
  background-color: #00fefe;
  border-radius: 200px;
  box-shadow: 0 0 0 2px #00fefe, 0 0 0 5.5px #00fefe;
}

.pyramid__points-item[data-current]:before, .pyramid__points-item[data-current]:after {
  z-index: 1;
  content: "";
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.pyramid__points-item[data-current]:after {
  animation: 2.6s linear infinite spin;
  box-shadow: -.5em .8em .8em .3em #00fefea6, -.6em -.6em .8em .3em #00fefea6;
}

.pyramid__points-item[data-current]:before {
  animation: 2.2s linear infinite reverse spin;
  box-shadow: .6em -.7em .4em #00fefea6, -.6em -.6em .4em #00fefea6;
}

.pyramid-title {
  margin: 0;
}

.widget--hero {
  position: relative;
}

.widget--swiper {
  z-index: 3;
  position: relative;
}

.widget--swiper .headline-medium {
  margin-top: calc(var(--v-offset-sm) * -1.5);
}

.widget--bottom {
  margin: var(--v-offset-md) auto 0;
  justify-content: center;
  display: flex;
}

.svg-icon {
  width: 100%;
  height: 100%;
}

.badge {
  place-content: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

.badge__icon {
  width: 55%;
  height: 55%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.badge[data-num]:before {
  content: attr(data-num);
  color: #ffc21d;
  place-content: center;
  font-size: 2.9em;
  font-weight: 700;
  line-height: 1;
  display: grid;
  position: absolute;
  inset: 0;
}

.badge[data-num][data-win]:before, .badge[data-num].badge--checkpoint:before {
  color: var(--c-indigo);
  font-weight: 820;
}

.hero {
  z-index: 2;
  grid-template: auto auto / auto;
  gap: 0;
  width: clamp(300px, 100vw - 30px, 1440px);
  margin: auto;
  display: grid;
  position: relative;
}

@media (min-width: 992px) {
  .hero {
    grid-template: auto / 1fr minmax(260px, auto);
    width: clamp(300px, 100vw - 80px, 1440px);
  }
}

.hero__bonus {
  min-width: auto;
}

@media (min-width: 992px) {
  .hero__bonus {
    min-width: 500px;
  }
}

.hero__pyramid {
  --v-offset: calc(60px + 5vh);
  min-height: 500px;
  max-height: calc(100vh - var(--v-offset) * 2);
  aspect-ratio: 420 / 722;
  margin: var(--v-offset) 0 10px;
  place-content: center;
  justify-self: center;
  display: grid;
  position: relative;
}

.hero__pyramid picture {
  width: 100%;
  height: 100%;
  display: block;
}

.hero__pyramid img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.badge-particles {
  z-index: -1;
  pointer-events: none;
  width: 220px;
  height: 220px;
  margin: auto;
  position: absolute;
  inset: 0 auto auto 0;
  transform: translate(-80px, -80px);
}

.badge-particles .particle {
  border-radius: 50%;
  animation-name: shoot, fade;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-delay: 0s;
  animation-fill-mode: none;
  animation-timeline: auto;
  position: absolute;
}

.badge-particles .particle:first-child {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.22s;
  animation-delay: -.5978s;
  transform: translate(170px, 130px);
}

.badge-particles .particle:nth-child(2) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.42s;
  animation-delay: -.426s;
  transform: translate(110px, 200px);
}

.badge-particles .particle:nth-child(3) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.82s;
  animation-delay: -.455s;
  transform: translate(100px, 140px);
}

.badge-particles .particle:nth-child(4) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.46s;
  animation-delay: -.9782s;
  transform: translate(160px, 170px);
}

.badge-particles .particle:nth-child(5) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.84s;
  animation-delay: -.4784s;
  transform: translate(60px, 70px);
}

.badge-particles .particle:nth-child(6) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.33s;
  animation-delay: -.0931s;
  transform: translate(80px, 70px);
}

.badge-particles .particle:nth-child(7) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.15s;
  animation-delay: -.644s;
  transform: translate(100px, 30px);
}

.badge-particles .particle:nth-child(8) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.24s;
  animation-delay: -.3472s;
  transform: translate(210px, 40px);
}

.badge-particles .particle:nth-child(9) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.85s;
  animation-delay: -.0925s;
  transform: translate(120px, 70px);
}

.badge-particles .particle:nth-child(10) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.02s;
  animation-delay: -.0714s;
  transform: translate(50px, 50px);
}

.badge-particles .particle:nth-child(11) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.72s;
  animation-delay: -1.4964s;
  transform: translate(170px, 140px);
}

.badge-particles .particle:nth-child(12) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.53s;
  animation-delay: -.153s;
  transform: translate(110px, 80px);
}

.badge-particles .particle:nth-child(13) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.53s;
  animation-delay: -.8721s;
  transform: translate(90px, 180px);
}

.badge-particles .particle:nth-child(14) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.47s;
  animation-delay: -1.3965s;
  transform: translate(30px, 220px);
}

.badge-particles .particle:nth-child(15) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.45s;
  animation-delay: -.5365s;
  transform: translate(210px, 110px);
}

.badge-particles .particle:nth-child(16) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.75s;
  animation-delay: -.595s;
  transform: translate(60px, 90px);
}

.badge-particles .particle:nth-child(17) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.6s;
  animation-delay: -.288s;
  transform: translate(40px, 210px);
}

.badge-particles .particle:nth-child(18) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.18s;
  animation-delay: -.885s;
  transform: translate(140px, 160px);
}

.badge-particles .particle:nth-child(19) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.34s;
  animation-delay: -.3752s;
  transform: translate(40px, 70px);
}

.badge-particles .particle:nth-child(20) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.38s;
  animation-delay: -1.3524s;
  transform: translate(130px, 80px);
}

.badge-particles .particle:nth-child(21) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.87s;
  animation-delay: -1.0659s;
  transform: translate(200px, 10px);
}

.badge-particles .particle:nth-child(22) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.13s;
  animation-delay: -.9944s;
  transform: translate(170px, 170px);
}

.badge-particles .particle:nth-child(23) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.95s;
  animation-delay: -.312s;
  transform: translate(210px, 50px);
}

.badge-particles .particle:nth-child(24) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.41s;
  animation-delay: -.9447s;
  transform: translate(150px, 150px);
}

.badge-particles .particle:nth-child(25) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.75s;
  animation-delay: -.245s;
  transform: translate(190px, 130px);
}

.badge-particles .particle:nth-child(26) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.8s;
  animation-delay: -.072s;
  transform: translate(210px, 90px);
}

.badge-particles .particle:nth-child(27) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.73s;
  animation-delay: -.4498s;
  transform: translate(190px, 120px);
}

.badge-particles .particle:nth-child(28) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.51s;
  animation-delay: -.5436s;
  transform: translate(170px, 140px);
}

.badge-particles .particle:nth-child(29) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.27s;
  animation-delay: -.2794s;
  transform: translate(90px, 50px);
}

.badge-particles .particle:nth-child(30) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.58s;
  animation-delay: -1.027s;
  transform: translate(70px, 50px);
}

.badge-particles .particle:nth-child(31) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.74s;
  animation-delay: -.5568s;
  transform: translate(160px, 160px);
}

.badge-particles .particle:nth-child(32) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.86s;
  animation-delay: -.651s;
  transform: translate(60px, 210px);
}

.badge-particles .particle:nth-child(33) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.12s;
  animation-delay: -.1008s;
  transform: translate(60px, 180px);
}

.badge-particles .particle:nth-child(34) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.66s;
  animation-delay: -.1494s;
  transform: translate(220px, 50px);
}

.badge-particles .particle:nth-child(35) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.08s;
  animation-delay: -.918s;
  transform: translate(140px, 50px);
}

.badge-particles .particle:nth-child(36) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.05s;
  animation-delay: -.861s;
  transform: translate(100px, 50px);
}

.badge-particles .particle:nth-child(37) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.12s;
  animation-delay: -.8512s;
  transform: translate(140px, 80px);
}

.badge-particles .particle:nth-child(38) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.29s;
  animation-delay: -.4644s;
  transform: translate(150px, 200px);
}

.badge-particles .particle:nth-child(39) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.62s;
  animation-delay: -1.5876s;
  transform: translate(170px, 190px);
}

.badge-particles .particle:nth-child(40) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.6s;
  animation-delay: -.496s;
  transform: translate(80px, 110px);
}

.badge-particles .particle:nth-child(41) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.92s;
  animation-delay: -.3072s;
  transform: translate(70px, 40px);
}

.badge-particles .particle:nth-child(42) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.18s;
  animation-delay: -.354s;
  transform: translate(70px, 220px);
}

.badge-particles .particle:nth-child(43) {
  background: khaki;
  width: 1px;
  height: 1px;
  animation-duration: 1.84s;
  animation-delay: -.368s;
  transform: translate(150px, 10px);
}

.badge-particles .particle:nth-child(44) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.58s;
  animation-delay: -.8374s;
  transform: translate(100px, 10px);
}

.badge-particles .particle:nth-child(45) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.52s;
  animation-delay: -.0152s;
  transform: translate(20px, 200px);
}

.badge-particles .particle:nth-child(46) {
  background: khaki;
  width: 4px;
  height: 4px;
  animation-duration: 1.23s;
  animation-delay: -.6642s;
  transform: translate(80px, 60px);
}

.badge-particles .particle:nth-child(47) {
  background: khaki;
  width: 3px;
  height: 3px;
  animation-duration: 1.79s;
  animation-delay: -.1969s;
  transform: translate(50px, 180px);
}

.badge-particles .particle:nth-child(48) {
  background: khaki;
  width: 2px;
  height: 2px;
  animation-duration: 1.23s;
  animation-delay: -.2829s;
  transform: translate(100px, 120px);
}

.badge-particles .particle:nth-child(49) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.58s;
  animation-delay: -1.1534s;
  transform: translate(10px, 70px);
}

.badge-particles .particle:nth-child(50) {
  background: khaki;
  width: 5px;
  height: 5px;
  animation-duration: 1.85s;
  animation-delay: -.4625s;
  transform: translate(30px, 150px);
}

@keyframes shoot {
  0% {
    transform: translate(110px, 110px);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hero-bonus {
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.hero-bonus__title {
  padding-top: var(--v-offset-md);
  text-align: center;
}

@media (min-width: 992px) {
  .hero-bonus__title {
    text-align: left;
  }
}

.hero-bonus__title .display-medium {
  margin: 0 0 calc(var(--v-offset-sm) / 3);
  color: var(--c-white-smoke);
  text-transform: uppercase;
  font-size: 4.3em;
  font-weight: 900;
}

.hero-bonus__title .headline-small {
  margin: 0 0 calc(var(--v-offset-sm) / 2);
  color: var(--c-white-smoke);
  font-size: 2.3em;
  font-weight: 700;
}

@media (min-width: 576px) {
  .hero-bonus__gifts {
    padding-top: var(--v-offset-sm);
    align-items: flex-end;
    min-height: 38%;
    display: flex;
  }
}

.hero-bonus__button {
  padding: var(--v-offset-sm) 0;
  flex: 1;
  justify-content: center;
  display: flex;
}

@media (min-width: 992px) {
  .hero-bonus__button {
    padding: var(--v-offset-sm) 0 var(--v-offset-lg);
  }
}

.hero-bonus__pyramid-abstract {
  padding-top: var(--v-offset-sm);
}

.hero-bonus__pyramid-abstract .headline-medium {
  margin: 0 0 calc(var(--v-offset-sm) * -.5);
}

@media (min-width: 992px) {
  .hero-bonus__pyramid-abstract {
    display: none;
  }
}

.hero-gifts {
  margin: var(--v-offset-sm) 0;
  grid-template: 1fr 1fr 1fr / auto;
  gap: 15px 0;
  display: grid;
}

@media (min-width: 576px) {
  .hero-gifts {
    grid-template: auto / 1fr 1fr 1fr;
    gap: 0 15px;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .hero-gifts {
    gap: 0 25px;
  }
}

.hero-gifts__item {
  grid-template: auto / fit-content(100px) auto;
  align-items: center;
  gap: 0 10px;
  display: grid;
}

@media (min-width: 576px) {
  .hero-gifts__item {
    gap: 20px 0;
    align-items: unset;
    grid-template: auto 1fr / auto;
  }
}

.hero-gifts__item .badge {
  width: 70px;
  height: 70px;
}

@media (min-width: 576px) {
  .hero-gifts__item .badge {
    width: 100%;
    height: 100%;
    max-height: 90px;
  }
}

.hero-gifts__item-desc {
  background-color: var(--c-indigo);
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 1px inset var(--c-dark-orchid);
  text-align: center;
  border-radius: 15px;
  place-content: center;
  padding: 14px;
  display: grid;
  overflow: hidden;
}

@media (min-width: 576px) {
  .hero-gifts__item-desc {
    place-content: unset;
    height: auto;
    display: block;
  }
}

@media (min-width: 1200px) {
  .hero-gifts__item-desc {
    padding: 18px 22px 20px;
  }
}

.hero-gifts__item-desc .title-medium {
  margin: 0 0 calc(var(--v-offset-sm) / 3);
  color: var(--c-white-smoke);
  font-size: 1.9em;
  font-weight: 800;
}

.hero-gifts__item-desc .body-medium {
  color: var(--c-white-smoke);
  margin: 0;
  font-size: 1.5em;
  font-weight: 460;
}

.btn-violet {
  min-width: fit-content;
  height: var(--el-size);
  border-radius: var(--el-size);
  background: linear-gradient(to left, var(--c-mauvein), var(--c-dark-orchid));
  color: var(--c-white-smoke);
  font-size: var(--font-size);
  letter-spacing: .5px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  place-content: center;
  font-weight: 600;
  text-decoration: none;
  display: inline-grid;
}

.btn-violet--sm {
  --el-size: 36px;
  --font-size: 16px;
  padding: 0 2em;
}

.btn-violet--md {
  --el-size: 44px;
  --font-size: 16px;
  padding: 0 3em 1px;
  box-shadow: 0 2px 4px #2d234266, inset 0 -3px #3a416f80;
}

@media (min-width: 1200px) {
  .btn-violet--md {
    --el-size: 52px;
    --font-size: 18px;
  }
}

.modal {
  z-index: var(--z-index-modal);
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  min-height: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.modal--out {
  pointer-events: none;
}

.modal__backdrop {
  z-index: 1;
  opacity: 0;
  background: #0f0f10;
  position: fixed;
  inset: 0;
}

.modal--in .modal__backdrop {
  opacity: .62;
  transition: opacity .4s cubic-bezier(0, 0, .5, 1);
}

.modal--out .modal__backdrop {
  opacity: 0;
  transition: opacity .55s cubic-bezier(.24, 0, .71, 1.01);
}

.modal__scroller {
  pointer-events: none;
  z-index: 2;
  place-content: center;
  min-height: 100%;
  display: grid;
  position: relative;
}

.modal__container {
  pointer-events: none;
  z-index: 3;
  width: 100vw;
  padding: 2rem;
  position: relative;
}

.modal-content {
  opacity: 0;
  border-radius: 1px;
  width: fit-content;
  margin: auto;
  display: flex;
  position: relative;
  transform: translateY(-30px);
}

.modal--in .modal-content {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.61, 1, .88, 1), transform .4s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.modal--out .modal-content {
  opacity: 0;
  transition: opacity .5s cubic-bezier(0, 0, .5, 1), transform .55s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.modal-content__close {
  cursor: pointer;
  z-index: 1;
  place-content: center;
  width: 50px;
  height: 50px;
  display: grid;
  position: absolute;
  inset: 0 0 auto auto;
  transform: translate(0, 45px);
}

.modal-content__close .svg-icon {
  width: 32px;
  height: 32px;
  fill: var(--c-white-smoke);
}

.card-bonus {
  --bg-body: #5a10e4;
  --bg-bottom: #5a10e4;
  --label-xp: #ffc21d;
  --badge-num: var(--c-white-smoke);
  width: calc(var(--app-wadth) / 4 - var(--app-gap) * 1.5);
  filter: drop-shadow(0 2mm 4mm #0003);
  gap: 10px 0;
  min-width: 260px;
  padding-top: 45px;
  display: grid;
}

.card-bonus--checkpoint {
  --bg-body: linear-gradient(0turn, #a516e5, #ffb901);
  --bg-bottom: linear-gradient(0turn, #8e13c8, #a715ec);
  --label-xp: var(--c-deep-purple);
  --badge-num: var(--c-deep-purple);
}

.card-bonus-checkpoint {
  background-color: var(--c-dark-brown);
  color: var(--c-white-smoke);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0ZBRkFGQSIgZD0iTTEyIDUuMzMzQTYuNjcgNi42NyAwIDAgMCA1LjMzMyAxMiA2LjY2OSA2LjY2OSAwIDAgMCAxMiAxOC42NjcgNi42NjkgNi42NjkgMCAwIDAgMTguNjY3IDEyIDYuNjY5IDYuNjY5IDAgMCAwIDEyIDUuMzMzWm0wIDEwYS42NjkuNjY5IDAgMCAxLS42NjYtLjY2NlYxMmMwLS4zNjcuMy0uNjY2LjY2Ni0uNjY2LjM2NyAwIC42NjcuMy42NjcuNjY2djIuNjY3YzAgLjM2Ny0uMy42NjctLjY2Ny42NjdabS42NjctNmEuNjY3LjY2NyAwIDEgMS0xLjMzMyAwIC42NjcuNjY3IDAgMCAxIDEuMzMzIDBaIi8+PC9zdmc+");
  background-position: 4px 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 12px 21px -8px;
  padding: 0 12px 0 30px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
}

.card-bonus__body {
  background: var(--bg-body);
  border-radius: 14px;
}

.card-bonus__body .badge {
  width: 90px;
  height: 90px;
  margin: -45px auto 0;
  position: relative;
}

.card-bonus__body .badge:before {
  color: var(--badge-num);
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
}

.card-bonus__body .label-large {
  text-align: center;
  color: var(--label-xp);
  margin: 12px 0 0;
  font-size: 20px;
  font-weight: 860;
}

.card-bonus__bottom {
  padding: 0 0 calc(var(--v-offset-sm) / 2);
  background: var(--bg-bottom);
  border-radius: 14px;
}

.card-bonus__bottom .btn-violet {
  width: fit-content;
  margin: auto;
  display: grid;
}

.bonus-list {
  grid-template: repeat(auto-fill, 1fr) / 1fr;
  gap: 2px 0;
  max-width: 360px;
  margin: 20px 0;
  list-style: none;
  display: grid;
}

.bonus-list--body .label {
  background-color: var(--c-white-smoke);
  color: #4a108b;
  border-radius: 7px;
  padding: 5px 6px;
  font-weight: 900;
  line-height: 1;
}

.bonus-list--body .label--up {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzRBMTA4QiIgZD0iTTcuNCAxMmgxLjU5djVjMCAuNTUuNDUgMSAxIDFoNGMuNTUgMCAxLS40NSAxLTF2LTVoMS41OWMuODkgMCAxLjM0LTEuMDguNzEtMS43MUwxMi43IDUuN2EuOTk2Ljk5NiAwIDAgMC0xLjQxIDBMNi43IDEwLjI5Yy0uNjMuNjMtLjE5IDEuNzEuNyAxLjcxWiIvPjwvc3ZnPg==");
  background-position: 0;
  background-repeat: no-repeat;
  padding-left: 22px;
}

.bonus-list li {
  min-height: 2.2em;
  color: var(--c-white-smoke);
  justify-content: space-between;
  align-items: center;
  gap: 0 20px;
  padding: 0 21px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.3;
  display: flex;
}

.bonus-list li span.label {
  white-space: nowrap;
  text-align: right;
}

.bonus-list li.lock {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1vcGFjaXR5PSIuNSIgZD0iTTE4IDcuNjY3aC0xVjUuNzYyQzE3IDMuMTMzIDE0Ljc2IDEgMTIgMVM3IDMuMTMzIDcgNS43NjJ2MS45MDVINmMtMS4xIDAtMiAuODU3LTIgMS45MDR2OS41MjRDNCAyMC4xNDMgNC45IDIxIDYgMjFoMTJjMS4xIDAgMi0uODU3IDItMS45MDVWOS41NzFjMC0xLjA0Ny0uOS0xLjkwNC0yLTEuOTA0Wm0tNiA4LjU3MWMtMS4xIDAtMi0uODU3LTItMS45MDUgMC0xLjA0Ny45LTEuOTA0IDItMS45MDRzMiAuODU3IDIgMS45MDRjMCAxLjA0OC0uOSAxLjkwNS0yIDEuOTA1Wk05IDcuNjY3VjUuNzYyYzAtMS41ODEgMS4zNC0yLjg1NyAzLTIuODU3czMgMS4yNzYgMyAyLjg1N3YxLjkwNUg5WiIvPjwvc3ZnPg==");
  background-position: 18px 50%;
  background-repeat: no-repeat;
  padding-left: 52px;
}

.bonus-list li.check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTIuNSIgY3k9IjExLjUiIHI9IjYuNSIgZmlsbD0iIzRBMTA4QiIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0xMC45NDkgMTIuNy0xLjQ1LTEuNDI1QS45MzMuOTMzIDAgMCAwIDguODEgMTFjLS4yNzUgMC0uNTEyLjEtLjcxMi4zYS45NDguOTQ4IDAgMCAwLS4yNzUuN2MwIC4yODQuMDkxLjUxNy4yNzUuN2wyLjE1IDIuMTVjLjIuMi40MzMuMy43LjMuMjY2IDAgLjUtLjEuNy0uM2w0LjI1LTQuMjVjLjItLjIuMjk2LS40MzMuMjg3LS43YTEuMDU0IDEuMDU0IDAgMCAwLS4yODctLjcgMS4wMiAxLjAyIDAgMCAwLS43MTMtLjMxMi45My45MyAwIDAgMC0uNzEyLjI4N0wxMC45NDkgMTIuN1ptLTIuOCA5LjA1LTEuNDUtMi40NS0yLjc1LS42YS45NDMuOTQzIDAgMCAxLS42LS4zODcuOTI4LjkyOCAwIDAgMS0uMTc1LS42ODhsLjI3NS0yLjgyNS0xLjg3NS0yLjE1YS45MzQuOTM0IDAgMCAxLS4yNS0uNjVjMC0uMjUuMDgzLS40NjYuMjUtLjY1TDMuNDQ5IDkuMmwtLjI3NS0yLjgyNWEuOTI4LjkyOCAwIDAgMSAuMTc1LS42ODcuOTQzLjk0MyAwIDAgMSAuNi0uMzg4bDIuNzUtLjYgMS40NS0yLjQ1YS45ODMuOTgzIDAgMCAxIC41NS0uNDM3Ljk3Ljk3IDAgMCAxIC43LjAzN2wyLjYgMS4xIDIuNi0xLjFhLjk3Ljk3IDAgMCAxIC43LS4wMzdjLjIzMy4wNzUuNDE2LjIyLjU1LjQzN2wxLjQ1IDIuNDUgMi43NS42Yy4yNS4wNS40NS4xOC42LjM4OC4xNS4yMDguMjA4LjQzNy4xNzUuNjg3TDIwLjU0OSA5LjJsMS44NzUgMi4xNWMuMTY2LjE4NC4yNS40LjI1LjY1cy0uMDg0LjQ2Ny0uMjUuNjVsLTEuODc1IDIuMTUuMjc1IDIuODI1YS45MjguOTI4IDAgMCAxLS4xNzUuNjg4Ljk0My45NDMgMCAwIDEtLjYuMzg3bC0yLjc1LjYtMS40NSAyLjQ1YS45ODMuOTgzIDAgMCAxLS41NS40MzguOTcuOTcgMCAwIDEtLjctLjAzOGwtMi42LTEuMS0yLjYgMS4xYS45Ny45NyAwIDAgMS0uNy4wMzguOTg0Ljk4NCAwIDAgMS0uNTUtLjQzOFoiLz48L3N2Zz4=");
  background-position: 18px 50%;
  background-repeat: no-repeat;
  padding-left: 52px;
}

@media (max-width: 1200px) {
  .swiper-wrapper {
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    max-width: 100%;
    margin: 0 auto -40px;
    padding-bottom: 40px;
    display: flex;
    overflow: scroll hidden;
  }

  .swiper-slide {
    scroll-snap-align: center;
    border: 0 solid #0000;
    display: flex;
  }

  .swiper-slide:not(:first-child):not(:last-child) {
    border-left-width: calc(var(--app-gap) / 2);
    border-right-width: calc(var(--app-gap) / 2);
  }

  .swiper-slide:first-child {
    border-left-width: var(--app-gap);
    border-right-width: calc(var(--app-gap) / 2);
  }

  .swiper-slide:last-child {
    border-left-width: calc(var(--app-gap) / 2);
    border-right-width: var(--app-gap);
  }
}

@media (min-width: 1200px) {
  .swiper {
    z-index: 1;
    max-width: 100vw;
    padding: 80px 0 0;
    list-style: none;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .swiper-wrapper {
    z-index: 1;
    width: 100%;
    height: 100%;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    display: flex;
    position: relative;
  }

  .swiper-button-prev, .swiper-button-next {
    background-color: var(--c-indigo);
    cursor: pointer;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTExLjI5IDguMjkyLTQuNTkgNC41OWEuOTk3Ljk5NyAwIDAgMCAxLjQxIDEuNDFsMy44OS0zLjg4IDMuODggMy44OGEuOTk3Ljk5NyAwIDEgMCAxLjQxLTEuNDFsLTQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMC0xLjQxIDBaIi8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 36px;
    border-radius: 48px;
    width: 48px;
    height: 48px;
    margin: auto;
    position: absolute;
    inset: 0 0 auto;
  }

  .swiper-button-prev {
    background-position: center;
    transform: translateX(-55px)rotate(-.25turn);
  }

  .swiper-button-next {
    background-position: center;
    transform: translateX(55px)rotate(.25turn);
  }

  .swiper-button-disabled {
    opacity: .5;
    cursor: default;
    filter: contrast(90%) grayscale(25%);
  }

  .swiper .card-bonus-container {
    scroll-snap-align: center;
    border-color: #0000;
    border-left-style: solid;
    border-left-width: 20px;
    border-right-style: solid;
    border-right-width: 20px;
  }

  .swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }

  .swiper-horizontal {
    touch-action: pan-y;
  }

  .swiper-slide-invisible-blank {
    visibility: hidden;
  }

  .swiper-backface-hidden .swiper-slide {
    backface-visibility: hidden;
    transform: translateZ(0);
  }
}

.table-benefits {
  gap: 0 var(--app-gap);
  padding: 0 var(--app-gap);
  contain: layout;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  color: var(--c-white-smoke);
  grid-template: auto 1fr auto auto / repeat(4, minmax(260px, 1fr));
  margin: 0 auto -40px;
  padding-bottom: 40px;
  display: grid;
  overflow: scroll hidden;
}

@media (min-width: 1200px) {
  .table-benefits {
    gap: 0 calc(var(--app-gap) * 2);
    width: calc(var(--app-wadth)  + var(--app-gap) * 2);
    padding-bottom: unset;
    overflow: unset;
    margin: auto;
  }
}

.table-benefits__icon {
  padding: calc(var(--v-offset-sm) / 2) 0 0;
  background-color: #b431ff;
  border-radius: 20px 20px 0 0;
}

.table-benefits__icon .badge {
  max-width: 132px;
  max-height: 132px;
  margin: auto;
}

.table-benefits__title {
  padding: calc(var(--v-offset-sm) / 2) calc(var(--v-offset-sm) / 2);
  background-color: #b431ff;
}

.table-benefits__title .title-medium {
  place-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 1.1;
  display: grid;
}

.table-benefits__desc {
  background-color: #b431ff;
  border-radius: 0 0 20px 20px;
  align-self: start;
  overflow: hidden;
}

.table-benefits__desc.unclamped {
  --turn: -1;
  --clamp: none;
}

.table-benefits__desc .body-medium {
  -webkit-line-clamp: var(--clamp, 3);
  padding: 0 calc(var(--v-offset-sm) / 2);
  -webkit-box-orient: vertical;
  margin: 0;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

.table-benefits__desc .clamp-button {
  margin-top: calc(var(--v-offset-sm) / 2);
  border-top: 4px solid var(--c-bg-page);
  cursor: pointer;
  background: linear-gradient(#a027ff, #7f13ff);
}

.table-benefits__desc .clamp-button .svg-icon {
  width: 48px;
  height: 48px;
  fill: var(--c-white-smoke);
  transform: scaleY(var(--turn, 1));
  margin: auto;
  transition: transform .325s ease-in-out;
  display: block;
}

.accordion {
  padding: 0 calc(var(--app-gap) / 2);
  color: var(--c-white-smoke);
  width: var(--app-wadth);
  grid-template: 1fr / auto;
  gap: 10px 0;
  margin: 0 auto;
  display: grid;
}

@media (min-width: 992px) {
  .accordion {
    padding: 0;
  }
}

.accordion .unclamped .svg-icon {
  transform: rotate(.5turn);
}

.accordion__item {
  --clamp-height: auto;
  background-color: var(--c-dark-purple);
  border-radius: 20px;
  grid-template: auto auto / 1fr;
  padding: 0 50px 0 20px;
  display: grid;
  position: relative;
}

@media (min-width: 1200px) {
  .accordion__item {
    padding: 0 60px 0 30px;
  }
}

.accordion__item:not(.unclamped) {
  --clamp-height: 0;
  background-color: var(--c-ultramarine);
}

.accordion__item .expand__icon {
  width: 48px;
  height: 48px;
  fill: var(--c-white-smoke);
  pointer-events: none;
  position: absolute;
  inset: 11px 10px 0 auto;
}

@media (min-width: 992px) {
  .accordion__item .expand__icon {
    inset: 15px 20px 0 auto;
  }
}

.accordion__item-desc {
  height: var(--clamp-height);
  margin: 0;
  transition: height 1.25s;
  overflow: hidden;
}

.accordion .title-medium {
  max-inline-size: auto;
  text-wrap: none;
  cursor: pointer;
  min-width: 100%;
  margin: 0;
  padding: 20px 0;
  line-height: 1.3;
}

.accordion .body-medium {
  padding: 0 0 20px;
  line-height: 1.3;
}

canvas {
  pointer-events: none;
  position: absolute;
  inset: auto 0 0;
}

.hero-background {
  background: url("hero-bg-mobile.88f9efd8.jpg") top / cover no-repeat;
  margin: auto;
  position: absolute;
  inset: 0;
}

@media (min-width: 992px) {
  .hero-background {
    background: url("hero-bg-desktop.39e1669c.jpg") top / cover no-repeat;
  }
}

.hero-background:after {
  content: "";
  z-index: 2;
  background: linear-gradient(transparent, var(--c-bg-page) 70%);
  pointer-events: none;
  width: 100%;
  height: 100px;
  position: absolute;
  inset: auto 0 0;
}

.tabs__nav {
  grid-template: 1fr / repeat(3, 1fr);
  gap: 0;
  width: fit-content;
  margin: auto;
  font-size: 12px;
  font-weight: 600;
  display: grid;
}

@media (min-width: 1200px) {
  .tabs__nav {
    padding: 0 60px 0 30px;
    font-size: 1.8em;
  }
}

.tabs__nav label {
  border-radius: 100px;
  padding: .4em .9em;
}

@media (min-width: 1200px) {
  .tabs__nav label {
    padding: .4em 1em;
  }
}

.tabs img {
  margin-bottom: var(--v-offset-sm);
  max-width: 100%;
  height: auto;
}

.tabs input[type="radio"], .tabs__body > div[class^="tab-"] {
  display: none;
}

.tabs input#tab-first ~ .tabs__nav .tab-first, .tabs input#tab-second ~ .tabs__nav .tab-second, .tabs input#tab-third ~ .tabs__nav .tab-third {
  color: var(--c-white-smoke);
  background-color: var(--c-dark-purple);
  cursor: pointer;
}

.tabs input#tab-first:checked ~ .tabs__nav .tab-first, .tabs input#tab-second:checked ~ .tabs__nav .tab-second, .tabs input#tab-third:checked ~ .tabs__nav .tab-third {
  color: var(--c-dark-purple);
  background-color: var(--c-white-smoke);
  cursor: default;
}

.tabs input#tab-first:checked ~ .tabs__body .tab-first, .tabs input#tab-second:checked ~ .tabs__body .tab-second, .tabs input#tab-third:checked ~ .tabs__body .tab-third {
  margin-top: var(--v-offset-sm);
  grid-template: auto auto / 1fr;
  justify-items: center;
  gap: 0;
  display: grid;
}

@media (min-width: 1200px) {
  .tabs input#tab-first:checked ~ .tabs__body .tab-first, .tabs input#tab-second:checked ~ .tabs__body .tab-second, .tabs input#tab-third:checked ~ .tabs__body .tab-third {
    gap: 0 var(--v-offset-md);
    grid-template: 1fr / 1fr 1fr;
    justify-items: start;
  }
}

.hide-scrollbar {
  height: calc(100% - 20px);
  padding-bottom: 0;
  overflow: hidden;
}

.demo {
  z-index: 9999;
  background: #dc143c;
  padding: 5px;
  font-size: 16px;
  position: fixed;
  inset: 0 auto auto 0;
}

.demo-auth {
  gap: 0 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.hero-bonus__progress img {
  margin-top: var(--v-offset-sm);
  max-width: 100%;
  height: auto;
}

html.demo-hero-unauthorised .hero-bonus__gifts {
  display: block;
}

html.demo-hero-unauthorised .hero-bonus__progress, html.demo-hero-authorised .hero-bonus__gifts {
  display: none;
}

html.demo-hero-authorised .hero-bonus__progress {
  display: block;
}

/*# sourceMappingURL=index.fa61a61b.css.map */
