.card-bonus {
  --bg-body: #5A10E4;
  --bg-bottom: #5A10E4;
  --label-xp: #FFC21D;
  --badge-num: var(--c-white-smoke);

  display: grid;
  gap: 10px 0;
  width: calc(var(--app-wadth) / 4 - (var(--app-gap) * 1.5));
  min-width: 260px;
  padding-top: 45px;
  filter: drop-shadow(0 2mm 4mm rgba(0, 0, 0, 0.2));

  &--checkpoint {
    --bg-body: linear-gradient(0turn, #A516E5, #FFB901);
    --bg-bottom: linear-gradient(0turn, #8E13C8, #A715EC);
    --label-xp: var(--c-deep-purple);
    --badge-num: var(--c-deep-purple);
  }

  &-checkpoint {
    margin: 12px 21px -8px;
    padding: 0 12px 0 30px;
    border-radius: 10px;
    background-color: var(--c-dark-brown);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0ZBRkFGQSIgZD0iTTEyIDUuMzMzQTYuNjcgNi42NyAwIDAgMCA1LjMzMyAxMiA2LjY2OSA2LjY2OSAwIDAgMCAxMiAxOC42NjcgNi42NjkgNi42NjkgMCAwIDAgMTguNjY3IDEyIDYuNjY5IDYuNjY5IDAgMCAwIDEyIDUuMzMzWm0wIDEwYS42NjkuNjY5IDAgMCAxLS42NjYtLjY2NlYxMmMwLS4zNjcuMy0uNjY2LjY2Ni0uNjY2LjM2NyAwIC42NjcuMy42NjcuNjY2djIuNjY3YzAgLjM2Ny0uMy42NjctLjY2Ny42NjdabS42NjctNmEuNjY3LjY2NyAwIDEgMS0xLjMzMyAwIC42NjcuNjY3IDAgMCAxIDEuMzMzIDBaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: left 4px center;
    color: var(--c-white-smoke);
    line-height: 30px;
    font-weight: 700;
    font-size: 12px;
  }

  &__body {
    background: var(--bg-body);
    border-radius: 14px;

    .badge {
      position: relative;
      width: 90px;
      height: 90px;
      margin: -45px auto 0;

      &::before {
        color: var(--badge-num);
        line-height: 1;
        font-size: 42px;
        font-weight: 700;
      }
    }

    .label-large {
      margin: 12px 0 0;
      text-align: center;
      color: var(--label-xp);
      font-size: 20px;
      font-weight: 860;
    }
  }

  &__bottom {
    padding: 0 0 calc(var(--v-offset-sm) / 2);
    border-radius: 14px;
    background: var(--bg-bottom);

    .btn-violet {
      display: grid;
      margin: auto;
      width: fit-content;
    }
  }
}
