.badge-particles {
  width: 220px;
  height: 220px;
  position: absolute;
  inset: 0 auto auto 0;
  z-index: -1;
  margin: auto;
  transform: translate(-80px, -80px);
  pointer-events: none;

  .particle {
    position: absolute;
    animation: shoot 3s ease-out infinite;
    animation-name: shoot, fade;
    border-radius: 50%;

    @for $i from 0 to 50 {
      $t: (1 + .01 * random(100)) * 1s;

      &:nth-child(#{ $i + 1 }) {
        $d: random(5) + px;

        width: $d; height: $d;
        transform: translate(random(22) * 10px, random(22) * 10px);
        //background: hsl(random(360) * 1deg, 80%, 80%);
        background: Khaki;
        //background: Gold;
        animation-duration: $t;
        animation-delay: -.01 * random(100) * $t;
      }
    }

    @keyframes shoot {
      0% { transform: translate(110px, 110px); }
    }

    @keyframes fade {
      0% { opacity: 1 }
      50% { opacity: 1 }
      100% { opacity: 0 }
    }
  }
}
