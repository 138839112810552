.hero-bonus {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
  height: 100%;

  &__title {
    padding-top: var(--v-offset-md);
    text-align: center;

    @include min(tablet-portrait) {
      text-align: left;
    }

    .display-medium {
      margin: 0 0 calc(var(--v-offset-sm) / 3);
      color: var(--c-white-smoke);
      font-weight: 900;
      font-size: 4.3em;
      text-transform: uppercase;
    }

    .headline-small {
      margin: 0 0 calc(var(--v-offset-sm) / 2);
      color: var(--c-white-smoke);
      font-weight: 700;
      font-size: 2.3em;
    }
  }

  &__gifts {
    @include min(phone-portrait) {
      padding-top: var(--v-offset-sm);
      min-height: 38%;
      display: flex;
      align-items: flex-end;
    }
  }

  &__button {
    padding: var(--v-offset-sm) 0;
    flex: 1;
    display: flex;
    justify-content: center;

    @include min(tablet-portrait) {
      padding: var(--v-offset-sm) 0 var(--v-offset-lg);
    }
  }

  &__pyramid-abstract {
    padding-top: var(--v-offset-sm);

    .headline-medium {
      margin: 0 0 calc(var(--v-offset-sm) * -0.5);
    }

    @include min(tablet-portrait) {
      display: none;
    }
  }
}
