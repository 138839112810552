.btn-violet {
  &--sm {
    --el-size: 36px;
    --font-size: 16px;

    padding: 0 2em;
  }

  &--md {
    --el-size: 44px;
    --font-size: 16px;

    @media (width >= 1200px) {
      --el-size: 52px;
      --font-size: 18px;
    }

    padding: 0 3em 1px;
    box-shadow: rgb(45 35 66 / 40%) 0 2px 4px, rgb(58 65 111 / 50%) 0 -3px 0 inset;
  }

  display: inline-grid;
  place-content: center;
  min-width: fit-content;
  height: var(--el-size);
  border-radius: var(--el-size);
  background: linear-gradient(to left, var(--c-mauvein), var(--c-dark-orchid));
  color: var(--c-white-smoke);
  font-size: var(--font-size);
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  user-select: none;
  touch-action: manipulation;
}
