.hero {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template: auto auto / auto;
  gap: 0;
  margin: auto;
  width: clamp(300px, calc(100vw - 30px), 1440px);

  @media (width >= 992px) {
    grid-template: auto / 1fr minmax(260px, auto);
    width: clamp(300px, calc(100vw - 80px), 1440px);
  }

  &__bonus {
    min-width: auto;

    @media (width >= 992px) {
      min-width: 500px;
    }
  }

  &__pyramid {
    --v-offset: calc(60px + 5vh);

    position: relative;
    display: grid;
    place-content: center;
    justify-self: center;
    min-height: 500px;
    max-height: calc(100vh - (var(--v-offset) * 2));
    aspect-ratio: 420 / 722;
    margin: var(--v-offset) 0 10px;

    picture {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
