html,
body {
  overflow-x: hidden;
  position: relative;
  width: 100vw;
}

body {
  min-height: 100vh;
}

.page {
  padding: 0 0 100px;
  background-color: var(--c-bg-page);
}
