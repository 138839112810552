.hero-background {
  position: absolute;
  inset: 0;
  margin: auto;
  background: url(../images/hero-bg-mobile.jpg) top center / cover no-repeat;

  @media (width >= 992px) {
    background: url(../images/hero-bg-desktop.jpg) top center / cover no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    inset: auto 0 0;
    z-index: 2;
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, var(--c-bg-page) 70%);
    pointer-events: none;
  }
}
