.tabs {
  &__nav {
    display: grid;
    grid-template: 1fr / repeat(3, 1fr);
    gap: 0;
    margin: auto;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;

    @include min(tablet-landscape) {
      padding: 0 60px 0 30px;
      font-size: 1.8em;
    }

    label {
      padding: 0.4em 0.9em;
      border-radius: 100px;

      @include min(tablet-landscape) {
        padding: 0.4em 1em;
      }
    }
  }

  img {
    margin-bottom: var(--v-offset-sm);
    max-width: 100%;
    height: auto;
  }

  input[type=radio],
  &__body > div[class^="tab-"] {
    display: none;
  }

  input#tab-first ~ .tabs__nav .tab-first,
  input#tab-second ~ .tabs__nav .tab-second,
  input#tab-third ~ .tabs__nav .tab-third {
    color: var(--c-white-smoke);
    background-color: var(--c-dark-purple);
    cursor: pointer;
  }

  input#tab-first:checked ~ .tabs__nav .tab-first,
  input#tab-second:checked ~ .tabs__nav .tab-second,
  input#tab-third:checked ~ .tabs__nav .tab-third {
    color: var(--c-dark-purple);
    background-color: var(--c-white-smoke);
    cursor: default;
  }

  input#tab-first:checked ~ .tabs__body .tab-first,
  input#tab-second:checked ~ .tabs__body .tab-second,
  input#tab-third:checked ~ .tabs__body .tab-third {
    margin-top: var(--v-offset-sm);
    display: grid;
    grid-template: auto auto / 1fr;
    gap: 0;
    justify-items: center;

    @include min(tablet-landscape) {
      grid-template: 1fr / 1fr 1fr;
      gap: 0 var(--v-offset-md);
      justify-items: start;
    }
  }
}
