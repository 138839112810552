:root {
  // z-orders
  --z-index-modal: 1000;

  // colors
  --c-bg-page: #32007C;
  --c-persian-indigo: #3B048C;
  --c-white-smoke: #F5F5F5;
  --c-dark-orchid: #AA14EA;
  --c-mauvein: #EF0392;
  --c-indigo: #51199C;
  --c-deep-purple: #532693;
  --c-dark-brown: #664725;
  --c-ultramarine: #4709A4;
  --c-dark-purple: #6505B7;

  //
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));

  @media (width >= 992px) {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  // fonts
  --min-fs: .8;
  --max-fs: 1;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
