.widget--hero {
  position: relative;
}

.widget--swiper {
  position: relative;
  z-index: 3;

  .headline-medium {
    margin-top: calc(var(--v-offset-sm) * -1.5);
  }
}

.widget--bottom {
  display: flex;
  justify-content: center;
  margin: var(--v-offset-md) auto 0;
}
